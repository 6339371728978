/* .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  background-color: white;
  scroll-snap-align: start;
}
.video__player {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border: 0px solid rgb(0, 0, 0);
  box-shadow: rgb(0, 0, 0) 0px 0px 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  width: 100%;
  gap: 0px;
  height: 100%;
  display: flex;
  padding: 0px;
  margin: 0px;
  flex-shrink: 0;
  z-index: 0;
  position: relative;
  font-size: inherit;
  overflow: hidden;
  flex-grow: 0;
  object-fit: cover;
  margin-top: -150px;

} */

.video-container {
  position: relative;
  background-color: #f0f0f0;
  width: 100%;
  height: 100vh;
  scroll-snap-align: start;
  overflow: visible;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.pagination-container {
  position: fixed;
  top: 40%;
  right: 3.5%;
  display: flex;
  align-items: center;
}

.pagination-dots {
  border-radius: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
}

.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}





@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-80%);
  }
}
