* {box-sizing:border-box}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}




.name_style {
  color: #000;
  font-size:26;
  font-family:"sans-serif-medium";
  font-weight:bolder;
  width:"88%";
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tab-list {
  border: 0.4px solid #CD6155;
  padding-left: 0;
   margin-left:20px;margin-right:20px;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  
  color:#CD6155;
  border-left: 0.5px solid #CD6155;
  width:33%;text-align: center;font-family: sans-serif;
 font-size: 16;

}

.tab-list-active {
  background-color:#CD6155;
  border: solid #CD6155;
  color:#fff;
  border-width: 1px 1px 0 1px;
}


.tab-list1 {
  border: 0.4px solid #CD6155;
  padding-left: 0;
  display: block;
    
  
}

.tab-list-item1 {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  color:#CD6155;
  background-color: #fff;
  border-left: 0.5px solid #CD6155;
  border-bottom: 0.5px solid #CD6155;
  padding: 0.5rem 0.75rem;
 
}

.tab-list-active1 {
  background-color:#CD6155;
  border: solid #CD6155;
  color:#fff;
  border-width: 1px 1px 0 1px;
}


.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

.App {

 
  align-items: center;
  justify-content: center;
  height: auto;
  text-align :center;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}
